<template>
    <el-row v-loading="isLoading">
        <el-col :span="20" :offset="2">
            <el-container direction="vertical" class="registrar-table">
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.registrationNumber") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.registration_number }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.registrationDate") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                              <p>{{ details.registration_date }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.updateDate") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.update_date }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.expirationDate") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.expiration_date }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.applicableStandards") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.applicable_standard }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.certificationStatus") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.certification_status }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.registeredOffices") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.registrant_name }}</p>
                            <p>{{ details.registered_office_name }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.specialNotes") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.special_notes }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.address") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.address }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="registrar-table__row">
                    <el-col :span="3">
                        <div class="registrar-table__left">
                            <p>{{ $t("table.registrationScope") }}</p>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="registrar-table__right">
                            <p>{{ details.registration_scope }}</p>
                        </div>
                    </el-col>
                </el-row>
            </el-container>
        </el-col>
    </el-row>
</template>

<script>
import { RegistrantHelper } from '../../../helper/registrant';

export default {
    name: 'RegistrarDetails',
    data() {
        return {
            isLoading: false,
            details: {},
        }
    },

    mounted() {
        const { registration_number } = this.$route.params;
        
        this.isLoading = true;
        RegistrantHelper.detail(registration_number, ({ data }) => {
            this.isLoading = false;
            this.details = data;
        });
    }
}
</script>

<style lang="scss" scoped>
p {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.registrar-table {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #aeaeae;

    &__row:not(:last-child) {
        border-bottom: 1px solid #aeaeae;
    }

    &__left {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-left: 1rem;
        color: #ffffff;
        background-color: #244b9f;
    }

    &__right {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-left: 1rem;
        color: #000000;
        background-color: #ffffff;
    }
}
</style>
import axios from "axios"
import TypeRequestEnum from "../enums/TypeRequestEnum"
import { credential, isArray, notif } from "../lib";
import router from "../router";
import { Notification } from 'element-ui';
class BaseHelper {
    static url = ""

    static handleResponse = async (response = {}, callback, config, err = {}) => {
        const { success = true, error = true, isToast = true, indexData } = config || {};

        let { 
            code,
            message = err.message,
            result,
            paging
        } = response.data || {};

        if(response.status === 401) { 
            const { pathname } = window.location
            if(!credential.storage.get("redirect")) credential.storage.delete(), credential.storage.set("redirect", pathname), router.push("/login");
        }

        let status = code === "0000"; 
        
        if(success && status) {
            if(isToast) notif({ message }) 
            else Notification.success({ title: "Success", message })
        }
        if(error && !status) {
            if(isToast) notif({ message, status: false })
            else Notification.error({ title: "Error", message })
        }

        if(typeof indexData !== "undefined" && isArray(result, 0)) result = result[indexData];
        
        const resp = { status, data: result, message, paging };
        
        if (callback) callback(resp);
        else return resp;
    }

    static async request(method, url, data, callback, config = {}) {
        const { auth = true } = config;
        
        let headers = { "Content-Type": "application/json" }

        if(auth && credential.storage.get("token")) headers.Authorization = `Bearer ${credential.storage.get("token")}`;

        const client = axios.create({ baseURL: url, json: true });

        return client({ method, data, headers, responseType: "json", })
            .then(async response => this.handleResponse(response, callback, config))
            .catch(async err => this.handleResponse(err.response, callback, config, err));
    }

    static post(url, data, callback, config) {
        return this.request(TypeRequestEnum.REQUEST_POST, this.url + url, data ? { data } : null, callback, config)
    }

    static put(url, data, callback, config) {
        return this.request(TypeRequestEnum.REQUEST_PUT, this.url + url, data ? { data } : null, callback, config)
    }

    static patch(url, data, callback, config) {
        return this.request(TypeRequestEnum.REQUEST_PATCH, this.url + url, data ? { data } : null, callback, config)
    }

    static delete(url, data, callback, config) {
        return this.request(TypeRequestEnum.REQUEST_DELETE, this.url + url, data ? { data } : null, callback, config)
    }

    static get(url, data, callback, config) {
        return this.request(TypeRequestEnum.REQUEST_GET, this.url + url, data ? { data } : null, callback, config)
    }

    static retrieve(url, data = {}, callback, config) {
        data = {
            data: {
                column: ["*"],
                ...data.data
            },
            paging: {
                page: 1,
                limit: 0,
                ...data.paging
            }
        }
        return this.request(TypeRequestEnum.REQUEST_POST, this.url + url, data ? data : null, callback, { success: false, error: false, ...config })
    }
}

export default BaseHelper;
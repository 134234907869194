<template>
  <el-container direction="vertical">
    <Heading :title = headingTitle />
    <SectionSearch />
    <SectionForm />
  </el-container>
</template>

<script>
import { Heading } from "../../components";
import SectionSearch from "./component/sectionSearch";
import SectionForm from "./component/sectionForm";

export default {
  name: 'Home',
  components: {
    Heading,
    SectionSearch,
    SectionForm
  },
  data() {
    return {
      headingTitle: this.$t("headingTitle")
    }
  }
}
</script>

<style>

</style>
<template>
    <component :is="component" />
</template>
<script>
export default {
    name: "AutoRoute",
    beforeMount(){
        const { name, meta } = this.$route;

        let { 
            path
        } = meta || {},
        access = [];
        
        this.component = {
            render(){
                try{
                    document.title = this.$t(`title`) || this.$t(`title-${name}`);

                    const PagesLocation = require(`@/pages${path}`).default;
                    return <PagesLocation access={access} />;
                }
                catch(err){
                    const PagesLocation = require(`@/components/partials/Error404`).default;
                    return <PagesLocation />;
                }
            }
        }
    },
    data(){
        return {
            component: {
                render(){
                    return <div></div>;
                }
            }
        }
    }
}
</script>
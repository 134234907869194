<template>
    <el-row type="flex" justify="center">
        <div class="button-container">
            <router-link to="/">
                <button class="button-cancel">{{ $t("action.close") }}</button>
            </router-link>
        </div>
    </el-row>
</template>

<script>
export default {
    name: 'Button',
}
</script>

<style lang="scss" scoped>
.button-container {
    margin-top: 1.25rem;
    margin-bottom: 2.25rem;
}

.button-cancel {
    padding: 0.75rem 2rem;
    border: none;
    border-radius: .5rem;
    color: black;
    background-color: #e0e0e0;
    transition: background-color 200ms ease;
    cursor: pointer;

    &:hover {
        background-color: #aeaeae;
    }
}
</style>
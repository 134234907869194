<template>
  <el-container direction="vertical">
      <Heading :title = headingTitle />
      <RegistrarDetails />
      <Button />
  </el-container>
</template>

<script>
import { Heading } from "../../components";
import RegistrarDetails from "./registrar-details";
import Button from "./partials/Button";

export default {
  name: 'Registrar',
  components: {
    Heading,
    RegistrarDetails,
    Button,
  },
  data() {
    return {
      headingTitle: this.$t("headingTitle")
    }
  }
}
</script>

<template>
  <el-row type="flex" class="row-bg" justify="center">
    <el-col :span="20">
      <div class="search-title">{{ $t("searchTitle") }}</div>
      <div class="underline-blue"></div>
      <div class="search-desc">{{ $t("searchDesc") }}</div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'SectionSearch'
}
</script>

<style lang="scss" scoped>
.search-title { 
  font-size: 18px;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.search-desc {
  font-size: 14px;
  margin-top: 1rem;
}

.underline-blue {
  width: 100%;
  height: 2px;
  background: #2e51a3;
}

</style>
